import React, { useEffect, useState } from "react";
import "./Anchor.css"
import anchorIcon from '../assets/anchor_top.png'


export default function Anchor() {

    const [showAnchor, setShowAnchor] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 400) {
                setShowAnchor(true);
            } else {
                setShowAnchor(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div
            className={`anchor-to-top ${showAnchor ? 'show' : ''}`}
            onClick={scrollToTop}
        >
            <img src={anchorIcon} alt='' />
        </div>
    )
}
