import React, { useEffect, useState } from 'react';
import Menu from './Menu';
import Categories from './Categories';
// import items from './data.json';
import { useTranslation } from 'react-i18next';
import Anchor from "./anchor/Anchor";
import en from "./assets/en.png"
import gr from "./assets/gr.png"
import logo from "./assets/logo.png"
import vareli from "./assets/vareli_metaximas.png"
import Loader from './Loader';

const sortMenu = ['Salads', 'Cheeses', 'Appetizers', 'Soft Drinks', 'Tsipouro', 'Beers', 'White Wines', 'Rosé Wines', 'Red Wines'];
function App() {
  const { i18n } = useTranslation();
  const [flag, setFlag] = useState(en);
  const [items, setItems] = useState(null);

  const handleChange = () => {
    i18n.changeLanguage(i18n.language === "en" ? "gr" : "en");
    setFlag(i18n.language === "en" ? gr : en);
  }

  useEffect(() => {
    fetch('https://metaximas-api.adaptable.app/menu', { method: 'GET' }).then((response) => {
      response.json().then((data) => {
        data.sort((a, b) => {
          return sortMenu.indexOf(a?.category?.en) - sortMenu.indexOf(b?.category?.en);
        });
        setItems(data);
      })
    }).catch((error) => {
      console.log(error);
    });

  }, [])

  if (items == null) {
    return <Loader />
  }
  return (
    <main>
      <section className="menu section">
        <div className='languageChange'>
          <img src={flag} onClick={handleChange} alt='flags' />
        </div>
        <div className="title">
          {/* <h2>Μεταξύ μας</h2> */}
          <div className='logoContainer'>
            <img className='logo' src={logo} alt='log_text' />
            <img className='vareli' src={vareli} alt='logo_barrel' />
          </div>
          <div className="underline" />
        </div>
        <Categories items={items} />
      </section>
      <section className="section">
        <Menu items={items} />
      </section>
      <Anchor />
    </main>
  );
}

export default App;
