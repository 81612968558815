import React from 'react';
import { Element } from 'react-scroll';
import ImageContainer from './ImgComponent';

import { useTranslation } from 'react-i18next';

const Menu = ({ items }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="section-center">
      {items.map((item, index) => {
        return (
          <Element name={item?.category?.[i18n.language]} key={index} className="category">
            <h3>{item?.category?.[i18n.language] || 'test'}</h3>
            <ul>
              {item?.items.map((value, index) => {
                const { name, val, price } = value;
                return (
                  <article key={index} className="menu-item">
                    <div className='icon-item'>
                      <ImageContainer src={'https://metaximas-api.adaptable.app/images/' +
                        name.en.toLowerCase() // Convert to lowercase
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .replace(/\s+/g, '_')
                        + '.jpeg'} />
                      <h4 className='item-title'>{name?.[i18n.language] || name.gr}</h4>
                      {val &&
                        <div className='item-title-val'>
                          <span>
                            {t(val)}
                          </span>
                        </div>}
                    </div>
                    {price > 0 && <h4 className="price">{price}€</h4>}
                  </article>
                );
              })
              }
            </ul>
          </Element>
        );
      })}
    </div>
  )
};

export default Menu;
