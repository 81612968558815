import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

const Categories = ({ items }) => {

  const { i18n } = useTranslation();
  const [selected, setSelected] = useState('Salad')
  // const categories = Object.keys(items);
  return (
    <div className="btn-container">
      {items.map((item, index) => {
        return (
          <div className={`filter-btn ${item?.category?.[i18n.language] === selected ? "active" : ""}`} key={index}>
            <Link
              type="button"
              className={'category-text'}
              key={index}
              to={item?.category?.[i18n.language]}
              smooth={true}
              offset={-185}
              duration={500}
              onClick={() => { setSelected(item?.category?.[i18n.language]) }}
            >
              {item?.category?.[i18n.language] || ''}
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Categories;
